import { CloseCircleFilled } from "@ant-design/icons"
import { Button, Space, Upload, message, notification } from "antd"
import ImgCrop from "antd-img-crop"
import "antd/lib/modal/style/index.css"
import "antd/lib/slider/style/index.css"
import { RcFile } from "antd/lib/upload"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { fileUpload } from "../../../../services"
import { DisplayFile, Wrapper } from "./imageUpload.style"
//import { blobToFile } from "../../../../../owner/utils/utilityFunction"

interface ImageUploadProps {
  onChange?: (fileName?: any, value?: any) => void
  uploadHint?: string
  isLoading?: (value: boolean) => void
  currentImage?: any
  isWidthBigger?: boolean
  isEditable?: boolean
}

export const blobToFile = (theBlob: Blob | Blob[], fileName: string): File => {
  const b: any = theBlob
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date()
  b.name = fileName
  //Cast to a File() type
  return theBlob as File
}

const ImageUploadWithEditFeature: React.FC<ImageUploadProps> = ({
  onChange,
  uploadHint,
  isLoading,
  currentImage,
  isWidthBigger,
}) => {
  const [file, setFile] = useState(null)
  const [originalFileName, setOriginalFileName] = useState(null)
  const [imageLoadError, setImageLoadError] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (currentImage != "") {
      setFile(currentImage)
      setOriginalFileName(
        currentImage?.file?.originFileObj?.name ||
          file?.file?.originFileObj?.name
      )
    }
  }, [currentImage])

  const { isLoading: uploadLoading, mutate } = useMutation(
    "fileUpload",
    fileUpload,
    {
      onSuccess: (result) => {
        isLoading(false)
        onChange && onChange(result?.data, result?.data)
        setFile(result?.data)
      },
      onError: (error?: any) => {
        isLoading(false)
        const msg = error?.data?.error?.message
        notification.error({
          message: msg ? t(`${msg}`) : t("An error has occurred"),
        })
      },
    }
  )

  const handleDeleteClick = () => {
    setFile(null)
    onChange && onChange(null, null)
  }

  const beforeUpload = (file: any) => {
    const isPdf = file.type == "application/pdf"
    const isLt5M = +(file.size / 1000000).toFixed(2) < 5

    if (!isLt5M) {
      message.error({
        content: t(
          "You can only upload JPG/PNG/GIF/HEIC image with less than 5MB"
        ),
        key: "37",
        icon: <CloseCircleFilled onClick={() => message.destroy("37")} />,
      })
    }
    if (isPdf) {
      return isPdf && isLt5M
    }
    return isLt5M
  }

  const handleChange = async (info) => {
    if (info?.file?.status === "done") {
      setOriginalFileName(info?.file?.originFileObj?.name)
    }
  }
  const onModalOk = (info: any) => {
    const bodyFormData = new FormData()
    bodyFormData.append("file", info)
    bodyFormData.append("model", "temp")
    mutate(bodyFormData)
  }

  return (
    <Wrapper widthMore={isWidthBigger}>
      {!file && (
        <>
          <ImgCrop
            zoomSlider={false}
            rotationSlider
            aspectSlider
            modalOk={t("Upload Image")}
            aspect={5 / 6}
            modalTitle={t("Edit")}
            onModalOk={onModalOk}
            beforeCrop={async (uploadedFile: RcFile) => {
              const isOk = beforeUpload(uploadedFile)
              if (!isOk) {
                return false
              }
              if (!uploadedFile.type || uploadedFile.type === "image/heic") {
                try {
                  const heic2any = (await import("heic2any")).default
                  const blobHeicFile = new Blob([uploadedFile])
                  const convertedFile = (await heic2any({
                    blob: blobHeicFile,
                    toType: "image/jpeg",
                    quality: 1,
                  })) as Blob
                  return blobToFile(convertedFile, uploadedFile.name)
                } catch (e) {
                  if (e?.code == 1) {
                    // this code specifies that even if file type is heic, it is aleady broswer readable
                    // usually when heic is uploaded & then downloaded
                    const fileName =
                      uploadedFile?.name?.split(".")?.[0] || "imagefromheic"
                    const newFile = new File([uploadedFile], fileName, {
                      type: "image/jpeg",
                    })
                    return newFile
                  } else if (e?.code == 2) {
                    // package doesn't support latest heic to use another package for this
                    const { heicTo, isHeic } = await import("heic-to")
                    const isImageHEIC = await isHeic(uploadedFile)
                    await isHeic(uploadedFile)
                    if (isImageHEIC) {
                      const blobHeicFile = new Blob([uploadedFile])
                      const jpeg = (await heicTo({
                        blob: blobHeicFile,
                        type: "image/jpeg",
                        quality: 0.1,
                      })) as Blob
                      return blobToFile(jpeg, uploadedFile.name)
                    }
                  }
                }
              }
              return uploadedFile
            }}
            modalClassName={"eassy_image_crop_modal"}
            modalProps={{
              style: {
                top: 40,
              },
            }}
          >
            <Upload
              showUploadList={false}
              onChange={handleChange}
              customRequest={({ onSuccess }) => {
                onSuccess("ok")
              }}
              accept={".jpeg,.jpg,.png,.gif,.heic"}
            >
              <div className={"file-area"}>
                <Button
                  loading={uploadLoading}
                  style={{
                    width: "fit-content",
                  }}
                >
                  {uploadLoading ? t("Uploading") : t("Select file")}
                </Button>
                <Space className={"min-width-class"}>
                  {t("File not selected")}
                </Space>
              </div>
              <p className={"upload-hint"}>
                {uploadHint ||
                  t(
                    "(Image: jpg,gif,png,HEIC/Size:300x222px/Maximum:640x640px)"
                  )}
              </p>
            </Upload>
          </ImgCrop>
        </>
      )}
      {file && (
        <DisplayFile>
          <div className={"file-name"}>
            {!imageLoadError ? (
              <img
                src={file}
                onLoad={() => setImageLoadError(false)}
                onError={() => setImageLoadError(true)}
              />
            ) : (
              <p>{originalFileName}</p>
            )}
          </div>
          <br />
          <div className={"delete-btn"}>
            <Button onClick={handleDeleteClick}>{t("Delete")}</Button>
          </div>{" "}
        </DisplayFile>
      )}
    </Wrapper>
  )
}

export { ImageUploadWithEditFeature }
